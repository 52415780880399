export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getSequences = `${BASE_URL}/sequences`;
export const getInitCommands = `${BASE_URL}/init`;
export const postScannedMAC = `${BASE_URL}/scan-mac`;
export const deviceResponse = `${BASE_URL}/device-response`;

export const getInputSequences = `${BASE_URL}/get-input-sequences`;
export const enableDisableInputSequence = `${BASE_URL}/input-sequence-enable-disable/`;
export const deleteInputSequence = `${BASE_URL}/delete-input-sequence/`;
export const addInputSequence = `${BASE_URL}/add-input-sequence/`;
export const getWisaCommands = `${BASE_URL}/get-wisa-commands/`;
export const editInputSequence = `${BASE_URL}/edit-input-sequence/`;
export const getInputSequenceDetails = `${BASE_URL}/get-input-sequence/`;
export const runInputSequence = `${BASE_URL}/run-input-sequence/`;
export const getSetCommandsInputs = `${BASE_URL}/get-possible-set-values`;

export const getInputCommands = `${BASE_URL}/get-input-commands`;
export const enableDisableInputCommand = `${BASE_URL}/input-command-enable-disable/`;
export const deleteInputCommand = `${BASE_URL}/delete-input-command/`;
export const addInputCommand = `${BASE_URL}/add-input-command/`;
export const editInputCommand = `${BASE_URL}/edit-input-command/`;

export const getCalibrationValue = `${BASE_URL}/calibration-values`;
export const calculateCalibrationValue = `${BASE_URL}/calculate-calibration-values`;
export const saveCalibrationTest = `${BASE_URL}/save-calibration-test`;

export const getCustomers = `${BASE_URL}/get-customers`;
export const enableDisableCustomer = `${BASE_URL}/customer-enable-disable/`;
export const addCustomer = `${BASE_URL}/add-customer`;
export const getCustomerInfo = `${BASE_URL}/customer-info-values`;
export const editCustomer = `${BASE_URL}/edit-customer/`;

export const getProductsList = `${BASE_URL}/get-products-list`;
export const enableDisableProduct = `${BASE_URL}/product-enable-disable`;
export const enableDisableModel = `${BASE_URL}/model-enable-disable`;
export const deleteModel = `${BASE_URL}/delete-model`;
export const getModelSizeList = `${BASE_URL}/model-size-values`;
export const addProduct = `${BASE_URL}/add-product`;
export const getModelsList = `${BASE_URL}/models-list`;
export const updateProduct = `${BASE_URL}/edit-product`;
export const editModel = `${BASE_URL}/edit-model`;
export const addModels = `${BASE_URL}/add-models`;
export const getModelUseCaseList = `${BASE_URL}/use-case-values`;
export const getAllRegionsList = `${BASE_URL}/get-region-list`;
export const expressTxConfigurationsRoute = `${BASE_URL}/get-wisa-express-tx-options-configurations`;

export const getAppVersion = `${BASE_URL}/get-app-version`;

export const addMacManually = `${BASE_URL}/add-mac-address`;
export const macAddressList = `${BASE_URL}/get-wisa-mac-addresses`;
export const uploadMacAddresses = `${BASE_URL}/upload-mac-addresses`;

export const getWisaMacForAssignment = `${BASE_URL}/assign-wisa-mac`;
export const postWisaMacAssignVerify = `${BASE_URL}/verify-assign-wisa-mac`;

export const getMacProcessInfo = `${BASE_URL}/get-mac-process-info`;
export const deleteFailedCSV = `${BASE_URL}/delete-failed-csv-file`;

export const uploadFirmware = `${BASE_URL}/upload-firmware`;
export const updateirmware = `${BASE_URL}/edit-firmware`;
export const getWisaFirmwareVersions = `${BASE_URL}/get-wisa-firmware-versions`;
export const getWisaProducts = `${BASE_URL}/get-wisa-products`;
export const changeCurrentCustomerVersion = `${BASE_URL}/change-current-customer-version`;

export const runDeviceCalibration = `${BASE_URL}/run-device-calibration`;
export const deviceCalibrationResponse = `${BASE_URL}/device-calibration-response`;

export const copyProduct = `${BASE_URL}/copy-product`;
export const copyModel = `${BASE_URL}/copy-model`;
export const getUsers = `${BASE_URL}/get-wisa-user`;
export const addUser = `${BASE_URL}/add-wisa-user`;
export const editUser = `${BASE_URL}/update-wisa-user`;
export const deleteUser = `${BASE_URL}/delete-wisa-user/`;
export const enableDisableUser = `${BASE_URL}/user-enable-disable/`;
export const getAllUsers = `${BASE_URL}/get-all-users`;

export const getRoleInfo = `${BASE_URL}/get-wisa-roles`;

export const addAmpConfig = `${BASE_URL}/add-amp-config`;
export const editAmpConfig = `${BASE_URL}/update-amp-config`;
export const getAmpConfigs = `${BASE_URL}/get-amp-config`;
export const changeCurrentAmpConfigVersion = `${BASE_URL}/change-amp-config-version`;

export const uploadMcuFirmwareVersion = `${BASE_URL}/upload-mcu-firmware`;
export const updateMcuFirmwareVersion = `${BASE_URL}/update-mcu-firmware`;
export const getMcuFirmwareVersions = `${BASE_URL}/get-mcu-firmware-versions`;
export const changeCurrentMcuFirmwareVersion = `${BASE_URL}/change-mcu-firmware-version`;

export const getModelConfigurationList = `${BASE_URL}/get-model-configuration-list`;

export const addChimeConfig = `${BASE_URL}/add-chime-config`;
export const editChimeConfig = `${BASE_URL}/update-chime-config`;
export const getChimeConfigs = `${BASE_URL}/get-chime-config`;
export const changeCurrentChimeConfigVersion = `${BASE_URL}/change-chime-config-version`;

export const getSerialProgrammingCommand = `${BASE_URL}/run-serial-programming`;

export const login = `${BASE_URL}/login`;

export const getCustomerModule = `${BASE_URL}/get-customer-module`;
export const editCustomerModule = `${BASE_URL}/update-customer-module`;

export const uploadApkVersion = `${BASE_URL}/upload-cert-app-version`;
export const getCertAppVersions = `${BASE_URL}/get-cert-app-versions`;
export const updateCurrentCustomerVersion = `${BASE_URL}/update-cert-app-ccv`;

export const getCertificationReports = `${BASE_URL}/cert/reports`;