import { useContext } from "react";
import { Link } from "react-router-dom";

import Images from "../../../assets/images";
import { AppContext } from "../../context/AppContext";
import styles from "./styles.module.scss";
import { NavigationRoutesEnum } from "../../interfaces/fe.interfaces";
import { CUSTOMER_MODULES, PERMISSIONS } from "../../utils/constants";
import AccessGuard from "../Guards/AccessGuard";

const Aside = () => {
  const appContext = useContext(AppContext);
  const { MANAGE_FIRMWARE_ROUTE: MANAGE_FIRMWARE } = NavigationRoutesEnum;

  return (
    <aside>
      <section className={`d-flex justify-content-center ${styles.logoSection}`}>
        <Link to="/">
          <img src={Images.WiSALogo} alt="WiSA Logo" className="w-50" />
        </Link>
      </section>
      <ul className="d-flex">
        <li>
          <Link to="/">MFG Home</Link>
        </li>
        <AccessGuard permission={PERMISSIONS.SEQUENCE_EXECUTION}>
          <AccessGuard modules={CUSTOMER_MODULES.CUSTOM_SEQUENCE}>
            <li>
              <Link to="/run-custom-seq">Custom Sequence Execution</Link>
            </li>
          </AccessGuard>
          {appContext?.pageData?.map((item) => (
            <li key={item.name}>
              <Link to={`/${item?.name}`}>{item?.description}</Link>
            </li>
          ))}
          <AccessGuard modules={CUSTOMER_MODULES.CALIBRATION}>
            <li>
              <Link to="/manual-calibration">Manual Calibration</Link>
            </li>
          </AccessGuard>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.CUSTOM_SEQUENCE_LIST} modules={CUSTOMER_MODULES.CUSTOM_SEQUENCE}>
          <li>
            <Link to="/manage-custom-seq">Manage Custom Sequence</Link>
          </li>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.CUSTOM_COMMAND_LIST} modules={CUSTOMER_MODULES.CUSTOM_COMMAND}>
          <li>
            <Link to="/manage-custom-commands">Manage Custom Commands</Link>
          </li>
        </AccessGuard>
        <AccessGuard
          permission={[PERMISSIONS.FIRMWARE_LIST, PERMISSIONS.AMP_CONFIGURATION_LIST, PERMISSIONS.MCU_FIRMWARE_VERSION_LIST]}
          modules={CUSTOMER_MODULES.MANAGE_SOFTWARE}>
          <li>
            <Link to={`/${MANAGE_FIRMWARE}`}>Manage Software</Link>
          </li>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.PRODUCT_LIST} modules={CUSTOMER_MODULES.MANAGE_PRODUCT}>
          <li>
            <Link to="/manage-product">Manage Product</Link>
          </li>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.CUSTOMER_LIST} modules={CUSTOMER_MODULES.MANAGE_CUSTOMER}>
          <li>
            <Link to="/manage-customer">Manage Customer</Link>
          </li>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.MAC_LIST} modules={CUSTOMER_MODULES.CALIBRATION}>
          <li>
            <Link to="/manage-macs">Manage MACs</Link>
          </li>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.USER_LIST} modules={CUSTOMER_MODULES.MANAGE_USER}>
          <li>
            <Link to="/manage-users">Manage Users</Link>
          </li>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.CERT_APP_GET}>
          <li>
            <Link to="/manage-cert-app">Manage Cert App Version</Link>
          </li>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.CERTIFICATION_REPORTS_GET}>
          <li>
            <Link to="/certification-reports">Certification Reports</Link>
          </li>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.SWAGGER}>
          {process.env.REACT_APP_SWAGGER_URL ? (
            <li>
              <a target="_blank" href={process.env.REACT_APP_SWAGGER_URL} rel="noreferrer">
                Swagger API
              </a>
            </li>
          ) : null}
        </AccessGuard>
        <li>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </aside>
  );
};

export default Aside;
