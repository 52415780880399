import { useContext, useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { Icons } from "../../assets/images";
import Layout from "../components/Layout";
import { NavigationRoutesEnum } from "../interfaces/fe.interfaces";

import layoutStyles from "../components/Layout/styles.module.scss";

import Home from "./Home";
import Calibration from "./Calibration";

import CustSeqExecution from "./CustSeqExecution";
import ManageCustomCommand from "./ManageCusCommand";
import ManageCustomer from "./ManageCustomer";

import ManageCustomSequence from "./ManageCustSeq";
import ModulePage from "./ModulePage";
import UpdateCustomSequence from "./UpdateCusSeq";

import ManageProduct from "./ManageProduct/ManageProductList";
import CreateProduct from "./ManageProduct/ManageProductCreate";
import UpdateProduct from "./ManageProduct/ManageProductUpdate";
import ManageFirmware from "./ManageFirmware/ManageFirmware";
import ManageFirmwareWisaFirmware from "./ManageFirmware/ManageFirmwareWisaFirmware/ManageFirmwareWisaFirmware";
import ManageFirmwareAmpConfiguration from "./ManageFirmware/ManageFirmwareAmpConfiguration/ManageFirmwareAmpConfiguration";
import ManageFirmwareMcuFirmware from "./ManageFirmware/ManageFirmwareMcuFirmware/ManageFirmwareMcuFirmware";
import ManageFirmwareChimeConfiguration from "./ManageFirmware/ManageFirmwareChimeConfiguration/ManageFirmwareChimeConfiguration";

import About from "./About";

import { AppContext } from "../context/AppContext";
import Spinner from "../components/Spinner";
import AuthGuard from "../components/Guards/AuthGuard";
import ManageMac from "./ManageMac";
import ManageUser from "./ManageUser";
import { CUSTOMER_MODULES, PERMISSIONS } from "../utils/constants";
import ManageCertAppVersion from "./ManageCertAppVersions/ManageCertAppVersion";
import CertificationReports from "./CertificationReports";

const { AMP_CONFIGURATION_ROUTE, MANAGE_FIRMWARE_ROUTE, MCU_FIRMWARE_ROUTE, CHIME_CONFIGURATION_ROUTE } = NavigationRoutesEnum;

const AppRoutes = () => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    // Fetch page data on load of Layout
    appContext?.fetchPageData();
  }, []);

  if (appContext?.isLoading) {
    return (
      <div className="h-100 w-100 d-flex align-item-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthGuard>
            <Layout>
              <Home />
            </Layout>
          </AuthGuard>
        }
      />
      {appContext?.pageData?.map((item) => (
        <Route
          path={`/${item.name}`}
          element={
            <AuthGuard permission={PERMISSIONS.SEQUENCE_EXECUTION}>
              <Layout>
                <ModulePage page={item.name} key={item.name} />
              </Layout>
            </AuthGuard>
          }
          key={item.name}
        />
      ))}
      <Route
        path="/manage-custom-seq"
        element={
          <AuthGuard permission={PERMISSIONS.CUSTOM_SEQUENCE_LIST} moduleAccess={CUSTOMER_MODULES.CUSTOM_SEQUENCE}>
            <Layout>
              <ManageCustomSequence execute={false} key="manage-seq" />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/update-custom-seq/:id"
        element={
          <AuthGuard permission={PERMISSIONS.CUSTOM_SEQUENCE_UPDATE} moduleAccess={CUSTOMER_MODULES.CUSTOM_SEQUENCE}>
            <Layout
              headerLeft={
                <Link to="/manage-custom-seq" className={layoutStyles.backBtn}>
                  <Icons.LeftArrow />
                  <span>Sequence List</span>
                </Link>
              }>
              <UpdateCustomSequence />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/run-custom-seq"
        element={
          <AuthGuard permission={PERMISSIONS.SEQUENCE_EXECUTION} moduleAccess={CUSTOMER_MODULES.CUSTOM_SEQUENCE}>
            <Layout>
              <ManageCustomSequence execute={true} key="execute-seq" />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/run-custom-seq/:id"
        element={
          <AuthGuard permission={PERMISSIONS.SEQUENCE_EXECUTION} moduleAccess={CUSTOMER_MODULES.CUSTOM_SEQUENCE}>
            <Layout>
              <CustSeqExecution />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-custom-commands"
        element={
          <AuthGuard permission={PERMISSIONS.CUSTOM_COMMAND_LIST} moduleAccess={CUSTOMER_MODULES.CUSTOM_COMMAND}>
            <Layout>
              <ManageCustomCommand />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/manual-calibration"
        element={
          <AuthGuard permission={PERMISSIONS.SEQUENCE_EXECUTION} moduleAccess={CUSTOMER_MODULES.CALIBRATION}>
            <Layout>
              <Calibration />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-customer"
        element={
          <AuthGuard permission={PERMISSIONS.CUSTOMER_LIST} moduleAccess={CUSTOMER_MODULES.MANAGE_CUSTOMER}>
            <Layout>
              <ManageCustomer />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-users"
        element={
          <AuthGuard permission={PERMISSIONS.USER_LIST} moduleAccess={CUSTOMER_MODULES.MANAGE_USER}>
            <Layout>
              <ManageUser />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-product"
        element={
          <AuthGuard permission={PERMISSIONS.PRODUCT_LIST} moduleAccess={CUSTOMER_MODULES.MANAGE_PRODUCT}>
            <Layout>
              <ManageProduct />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-product/create"
        element={
          <AuthGuard permission={PERMISSIONS.PRODUCT_ADD} moduleAccess={CUSTOMER_MODULES.MANAGE_PRODUCT}>
            <Layout>
              <CreateProduct />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-product/update/:id"
        element={
          <AuthGuard permission={PERMISSIONS.PRODUCT_UPDATE} moduleAccess={CUSTOMER_MODULES.MANAGE_PRODUCT}>
            <Layout>
              <UpdateProduct />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-macs"
        element={
          <AuthGuard permission={PERMISSIONS.MAC_LIST} moduleAccess={CUSTOMER_MODULES.CALIBRATION}>
            <Layout>
              <ManageMac />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-cert-app"
        element={
          <AuthGuard permission={PERMISSIONS.CERT_APP_GET}>
            <Layout>
              <ManageCertAppVersion />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path="/certification-reports"
        element={
          <AuthGuard permission={PERMISSIONS.CERTIFICATION_REPORTS_GET}>
            <Layout>
              <CertificationReports />
            </Layout>
          </AuthGuard>
        }
      />
      <Route
        path={MANAGE_FIRMWARE_ROUTE}
        element={
          <AuthGuard permission={PERMISSIONS.FIRMWARE_LIST} moduleAccess={CUSTOMER_MODULES.MANAGE_SOFTWARE}>
            <Layout>
              <ManageFirmware />
            </Layout>
          </AuthGuard>
        }>
        <Route
          index
          element={
            <AuthGuard permission={PERMISSIONS.FIRMWARE_LIST}>
              <ManageFirmwareWisaFirmware />
            </AuthGuard>
          }
        />
        <Route
          path={AMP_CONFIGURATION_ROUTE}
          element={
            <AuthGuard permission={PERMISSIONS.AMP_CONFIGURATION_LIST}>
              <ManageFirmwareAmpConfiguration />
            </AuthGuard>
          }
        />
        <Route
          path={MCU_FIRMWARE_ROUTE}
          element={
            <AuthGuard permission={PERMISSIONS.MCU_FIRMWARE_VERSION_LIST}>
              <ManageFirmwareMcuFirmware />
            </AuthGuard>
          }
        />
        <Route
          path={CHIME_CONFIGURATION_ROUTE}
          element={
            <AuthGuard permission={PERMISSIONS.CHIME_CONFIGURATION_LIST}>
              <ManageFirmwareChimeConfiguration />
            </AuthGuard>
          }
        />
      </Route>
      <Route
        path="/about"
        element={
          <AuthGuard>
            <Layout>
              <About />
            </Layout>
          </AuthGuard>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
