import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import Modal from "../Modal/Modal";

import styles from "./styles.module.scss";
import { Formik, FormikHelpers } from "formik";
import { RunOptions, useFetch } from "../../hooks/useFetch";
import { GetCustomersResponse, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { copyProduct, getCustomers } from "../../api/config";
import Select from "../Select";
import Spinner from "../Spinner";
import { http } from "../../api/utils/http";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { validateCopyProduct } from "../../utils/validationSchemas";
import { handleNumberInputChange } from "../../utils/helpers";

interface CopyProductProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  customerProductId: number;
  vendorId: string;
  refreshList: (options?: RunOptions) => void;
}

interface CopyProductFormValues {
  new_vendor_id: number | null;
  new_product_id: number | null;
}

const initialValues: CopyProductFormValues = {
  new_vendor_id: null,
  new_product_id: null,
};

const CopyProduct = ({ show, setShow, customerProductId, vendorId, refreshList }: CopyProductProps) => {
  /**
   * Fetch list of customers to show in dropdown
   * only active customers are displayed in dropdown
   */
  const { loading, data: customerListRepsonse } = useFetch<GetCustomersResponse>({
    runOnMount: true,
    url: getCustomers,
  });

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  // Submit handler for copy product form
  const handleSubmit = useCallback(
    (values: CopyProductFormValues, { setSubmitting }: FormikHelpers<CopyProductFormValues>) => {
      const payload = {
        ...values,
        customer_product_id: customerProductId,
        new_product_id: values.new_product_id ? +values.new_product_id : null,
      };

      // Success handler for copy product api
      const onSuccess = (res: AxiosResponse<UpdateItemResponse>) => {
        if (res.data.status) {
          toast.success(res.data.message);

          // Close dialogbox and refresh list
          setShow(false);
          refreshList();
        } else {
          toast.error(res.data.message);
        }

        setSubmitting(false);
      };

      http.makePostRequest<UpdateItemResponse, { message: string }>(
        copyProduct,
        onSuccess,
        (error) => {
          toast.error(error?.response?.data?.message);
          setSubmitting(false);
        },
        payload
      );
    },
    [customerProductId]
  );

  // Show list of customers that are active in the dropdown.
  const customerList = useMemo(() => {
    if (!customerListRepsonse) return [];

    return [
      { label: "Select Customer", value: null },
      ...customerListRepsonse.data.customers
        .filter((item) => item.is_enable)
        .map((customer) => ({
          label: customer.customer_name,
          value: customer.vendor_id,
        })),
    ];
  }, [customerListRepsonse]);

  const validationSchema = useMemo(() => validateCopyProduct(vendorId), [vendorId]);

  return (
    <Modal isModalOpen={show} onModalClose={handleClose}>
      <div className={styles.dialogContainer}>
        <p className={styles.title}>Copy Product</p>
        {!loading && customerList ? (
          <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ values, setFieldValue, handleSubmit, errors, touched, handleBlur }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className={styles.fieldContainer}>
                  <p>1. Select customer to copy this product for</p>
                  <Select
                    selectProps={{
                      value: values.new_vendor_id ?? "",
                      onChange: (e) => setFieldValue("new_vendor_id", e.target.value),
                    }}
                    options={customerList}
                  />
                  {errors?.new_vendor_id && touched?.new_vendor_id && <p className={styles.error}>{errors?.new_vendor_id}</p>}
                </div>
                <div className={styles.fieldContainer}>
                  <p>2. Enter new product id</p>
                  <input
                    type="text"
                    placeholder="Product Id"
                    name="new_product_id"
                    value={values.new_product_id ?? ""}
                    onBlur={handleBlur}
                    onChange={(e) => handleNumberInputChange(e, setFieldValue)}
                  />
                  {errors?.new_product_id && touched?.new_product_id && <p className={styles.error}>{errors?.new_product_id}</p>}
                </div>
                <div className={styles.buttonsContainer}>
                  <button type="submit">Copy</button>
                  <button onClick={handleClose}>Close</button>
                </div>
              </form>
            )}
          </Formik>
        ) : (
          <Spinner />
        )}
      </div>
    </Modal>
  );
};

export default CopyProduct;
